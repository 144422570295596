import React from 'react'
import Layout from '../../components/layout/Layout';
import { Disqus, CommentCount } from 'gatsby-plugin-disqus'
import '../careers.css'
import { Link } from 'gatsby';
import { Box, Stack, Typography } from '@mui/material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';

export default function Example() {
  let disqusConfig = {
    url: 'https://www.c4scale.com/blogs/the-data-quality-jigsaw',
    identifier: '1',
    title: 'The Data Quality Jigsaw: Why & How',
  }
  return (
    <>
    <head>
        <title>C4Scale - Blog page</title>
        <meta name="description" content="The Data Quality Jigsaw: Why & How"></meta>
      </head>
    <Layout>
      <div>
        <div className="max-w-screen-xl mx-auto p-5 sm:p-10 md:p-16 relative">
          <div className="bg-cover h-64 text-center overflow-hidden" style={{ height: '450px', backgroundImage: 'url("https://res.cloudinary.com/durti17dk/image/upload/v1716885160/blog-assets/Data_quality_Main_Picture_fjmdrb_q6ov0c.webp")' }} title="">
          </div>
          <div className="max-w-4xl mx-auto">
            <div className="mt-3 bg-white rounded-b lg:rounded-b-none lg:rounded-r flex flex-col justify-between leading-normal">
              <div className="my-10">
                <a href="/#" className="text-xs text-indigo-600 uppercase font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  The Data Quality Jigsaw
                </a>
              
                <h1 className="text-gray-900 font-bold text-3xl mb-2">The Data Quality Jigsaw: Why & How</h1>
                <p className="text-gray-700 text-xs mt-2">Written By: <a href="https://www.linkedin.com/in/chakravarthyvp/" className="text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out" target='blank'>
                  Chakravarthy Varaga
                </a></p>
                <h3 className="text-2xl font-bold my-5">Unreliable Data Leads to Hindered Decision Making</h3>
                <p className="text-21 leading-8 my-5">
                  Imagine your finance team presenting a financial health report to the CEO, encompassing various metrics such as revenues, profits, and cash flows categorized by product. It's vital that essential business metrics, like Total Bookings, Total Revenues by product, Total Spend on products, Available Cash, etc., are accurate to validate the financial health. Accurate data empowers the CEO to make informed decisions in line with business growth.</p>
                <h3 className="text-xl font-bold my-5">Inaccuracies in the report quality can result in:</h3>
                <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                  <li>Impeded business decisions hindering growth.</li>
                  <li>Questions about the ROI of data teams, potentially leading to reduced investments.</li>
                  <li>Disconnection among teams within the organization.</li>
                </ul>
                <p className="text-21 leading-8 my-5">
                  The quality of data also mirrors the inefficiencies and operational complexities within the organization.</p>
                <h3 className="text-2xl font-bold my-5">Why Would a CTO/CDO Invest in a Data Team?</h3>
                <p className="text-21 leading-8 my-5">
                  The CTO's/CDO’s willingness to invest in a data(engineering) team hinges on their ability to produce accurate, reliable data. Data Quality is a measure that directly impacts the ROI of a Data Team.</p>
                <p className="text-21 leading-8 my-5">
                  Advocacy for data teams often comes from sponsors such as product teams, marketing, and finance, who heavily depend on various metrics like Business Metrics, North Star Metrics, Product Metrics, and Engineering Platform Metrics. Data Quality is a measure of the success of the partnerships between product, data, and business.</p>
                <p className="text-21 leading-8 my-5">
                  The quality of data also mirrors the inefficiencies and operational complexities within the organization.</p>
                <h3 className="text-2xl font-bold my-5">Data Warehouse and Data Lake: Central Hubs with a Pitfall</h3>
                <p className="text-21 leading-8 my-5">
                  A Data Warehouse acts as a centralized hub for storing diverse data types, structured and unstructured, at any scale. Its primary goal is to mitigate data silos within an organization by providing a unified storage solution. By consolidating data into a single repository, it facilitates seamless integration and analysis of data from various sources across the organization. However, as the Data Warehouse expands, managing the data becomes increasingly complex.</p>
                <p className="text-21 leading-8 my-5">
                  From my past experience in managing data teams: For companies operating at scale, envision managing over 20 product lines generating thousands of tables with dozens of columns each. These tables undergo daily transformations and enrichments via 1000s of pipelines, resulting in terabytes of data flowing daily. Amidst this data ecosystem, involving tens of analysts, product teams, and data scientists, understanding the domain, validating data, and its usage, any glitch in the source or pipelines can escalate the data quality problem.</p>
                <img className="w-full h-full object-cover" src="https://res.cloudinary.com/durti17dk/image/upload/v1716885159/blog-assets/b2_dpzp6n_chnnxb.webp" alt="" />
                <p className="text-21 leading-8 my-5">
                  The data storage process initiates with data generation by the data producer, who then transmits the data to the data infrastructure and stores it in the data lake. This data is then enriched and output to the data warehouse. Subsequently, downstream consumers (Data Science, BI, Analytics/Reporting, Analysts, Applications) face challenges in comprehending the data within the data lake or the warehouse due to a lack of domain knowledge about the data producer. To bridge this understanding gap, consumers often consult the data producer to grasp the intricacies of the data.</p>
                <p className="text-21 leading-8 my-5">
                  <i>As the Data Warehouse grows in size and complexity, it can transition from being a strategic advantage to a technical debt for the organization.</i></p>
                <h3 className="text-2xl font-bold my-5">Understanding Data Quality</h3>
                <p className="text-21 leading-8 my-5">
                  <i>Data quality is good when the data is reliable and meaningful. Several factors determine this reliability:</i></p>
                <p className="text-21 leading-8 my-5">
                  <b>Accuracy:</b> Is the data accurate? For example, are the total average orders/day for the last 7 days accurate? Can we corroborate this with verifiable alternate pipelines?<br />
                  <b>Consistency:</b>  Inconsistencies can lead to problems. For instance, if marketing campaigns are personalized based on the 'current_country' field, a discrepancy can lead to a bad customer experience.<br />

                  <b>Completeness:</b> Different stakeholders have varying expectations of data completeness. Business heads might focus on error percentages or deviations in presented metrics, while product and engineering teams determine mandatory fields in domain entities.
                  <br />

                  <b> Validity:</b> Validity often depends on business and product teams. Representing customer addresses consistently across geographies is an example of maintaining validity.<br />

                  Apart from these characteristics, addressing two fundamental problems is crucial to resolve data quality issues:<br />

                  <b>Governance & Integrity:</b> A lack of proper data governance can result in unintended consequences. For example, an incorrect promotional campaign sent to the wrong country due to improper access can lead to a negative customer experience.<br />

                  <b>Ownership & Accountability:</b> Data quality is a shared responsibility. Different data elements have different owners at various stages of the pipeline, and ensuring a clear lineage of changes and raising alerts for respective owners and consumers is essential.
                </p>
                <img className="w-full h-full object-cover" src="https://res.cloudinary.com/durti17dk/image/upload/v1716885159/blog-assets/b23_tlmvxk_cvfmly.webp" alt="" />
                <p className="text-21 leading-8 my-5">
                If the format of 'order_price' changes, it can disrupt downstream pipelines and metrics owned by other teams. Similarly, pipeline breaks, even without format changes, can lead to data loss and inaccuracies in metrics.</p>
                <p className="text-21 leading-8 my-5">
                  <i>In essence, "data quality" is a collective responsibility involving all stakeholders.</i></p>
                  <h3 className="text-2xl font-bold my-5">Addressing Data Quality Challenges: Data Contracts</h3>
                <p className="text-21 leading-8 my-5">
                While there's no one-stop solution to address data quality challenges, one effective approach is to left-shift the detection of breaking changes to the data source or ownership. This entails an automated process for reviewing and approving changes with data producers.</p>
                <p className="text-21 leading-8 my-5">
                The industry is beginning to embrace the concept of "Data Contracts." These contracts serve as schematic representations of data agreements between data producers/owners and data consumers. They act as a means to enforce end-to-end data quality. When a producer modifies the data, downstream data owners and applications are promptly alerted. This forces the producer to review the changes and cascade them downstream if necessary.</p>
                <p className="text-21 leading-8 my-5">
                From a technical perspective, the solution involves defining the contract and validating it at various stages.</p>
                <h3 className="text-xl font-bold my-5">Data Contract Definition Should Include:</h3>
                <ul style={{ listStyleType: 'disc', lineHeight: '1.8rem', marginLeft: '1.3rem', fontSize: '18px' }}>
                  <li>Schema: Data field or data set schemas, allowed ranges, data types, allowed values, lengths, etc.</li>
                  <li>Semantic Definition: Downstream applications, their owners, and metrics powered by the data field.</li>
                  <li>SLA Definitions: Data freshness, validity, and allowed data latency.</li>
                  <li>Alerting: Teams to be informed of changes.</li>
                  <li>Governance Layer: Access permissions, data sensitivity, PII information, and roles allowed to access the column.</li>
                </ul>
                <p className="text-21 leading-8 my-5">
                <b>Validation:</b> This process involves validating and enforcing contracts at various stages of the pipelines.
</p>
<h3 className="text-2xl font-bold my-5">Wrapping Up</h3>
                <p className="text-21 leading-8 my-5">
                In today's economic landscape, prioritizing cost-efficiency in data management, enhancing data return on investment (ROI), and leveraging existing tools and platforms are critical strategies. This approach is favored over allocating additional resources to address data quality issues.</p>
                <p className="text-21 leading-8 my-5">
                Transferring ownership responsibilities to producers shifts the focus towards data contracts, elevating their significance in upholding data quality and fostering a collaborative environment in organizations that emphasize data.</p>
                <p className="text-21 leading-8 my-5">
                The adoption and integration of data contracts within broader governance frameworks within organizations will be a fascinating development to observe within the community.</p>
                <div className="mt-6 mb-6">
                    <Link
                      to="https://calendly.com/chakravarthy-varaga/introductory-call" target='blank'
                      className="w-max flex items-center justify-center px-4 py-2 border border-transparent rounded-md shadow-sm text-base font-medium text-white bg-indigo-600 hover:bg-indigo-700"
                    >
                      Talk to our product
                    </Link>
                  </div>
                <span>
              <a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  #dataquality 
                </a><a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  #datascale 
                </a><a href="/#" className="text-md text-indigo-600 font-medium hover:text-gray-900 transition duration-500 ease-in-out">
                  #datacontracts
                </a></span>
              </div>
              {/* <div className="my-10"> */}

              {/* </div> */}
              
            </div>
          </div>
        </div>
      </div>
      <Stack
          direction="row"
          justifyContent="space-between"
          width="100%"
          sx={{ marginTop: "20px", padding: "0 20px", marginBottom: "20px" }}
        >
          <Link to="/blogs/genai-adoption-mitigating-risks" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold" }} />
              <Typography variant="h6" sx={{ fontSize: "18px" }}>Previous</Typography>
            </Box>
          </Link>
          <Link to="/blogs/elastic-search-to-s3-data-migration" style={{ textDecoration: 'none' }}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '10px', color: '#1f2937' }}>
              <Typography variant="h6" sx={{ fontSize: "18px" }}>Next</Typography>
              <ArrowBackIosNewIcon sx={{ color: "#111827", fontSize: "18px", fontWeight: "bold", transform: 'rotate(180deg)' }} />
            </Box>
          </Link>
        </Stack>
      <section style={{ padding: '0 4rem' }}>
        <CommentCount config={disqusConfig}  />
        <Disqus config={disqusConfig} />
      </section>
    </Layout>
    </>
  )
}
